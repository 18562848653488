import './styles/App.css';
import LoginComponent from './components/google_login'
import { GoogleOAuthProvider } from '@react-oauth/google'
import React, { useEffect, useState } from 'react';

// prod client id = 891758090204-c7tbrtvgnuupe21uv2pu3lkij150rm7d.apps.googleusercontent.com
// Dev  client id = 891758090204-d5qhkdtrnjufbnr36vji6jbj04t6s73f.apps.googleusercontent.com

function App() {

    const [data, setData] = useState([]);

    useEffect(() => {

        const token = localStorage.getItem('state');
	    const url = 'http://localhost:7800/login';
        const options = {
            method: 'POST',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json'
            }

        };

        fetch(url, options)
        .then(response =>{
            
            //response.json()

            console.log('Response :', response);

            if (response.ok) {
                
                if ( response.redirected ) {

                    document.location.url = response.url;
    
                }
            }
            
        
        })
        .then(data => {

            
            setData(data);

        })
        .catch(error => console.error('Error fetching user info :', error));



    }, []);


  return (

	<GoogleOAuthProvider clientId='891758090204-c7tbrtvgnuupe21uv2pu3lkij150rm7d.apps.googleusercontent.com'>

		<div className="App">

			<div className='login_box'>		

				<p>Sign In to Africa Bingo</p>
				<LoginComponent></LoginComponent>

			</div>

		</div>
	</GoogleOAuthProvider>
			
  );
}

export default App;
