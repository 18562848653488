import React from 'react';
import { GoogleButton } from 'react-google-button';
import { useGoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';


const sendDataToServer = async ( data ) => {

    const token = localStorage.getItem('state');
    console.log(token);

    const url = '/login';
    const options = {
        method: 'POST',
        headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)

    };

    try {
        const response = await fetch(url, options);
        console.log(response);

        if ( response.redirected ) {

            document.location.href = response.url;

        }

        if (response.ok) {
            
            const jsonResponse = await response.json();
            
            console.log('Data sent successfully:', jsonResponse);

            localStorage.setItem('state', jsonResponse.token);
            document.location.href = '/dashboard';

            
        } else {
            console.error('Failed to send data to server');
        }
    } catch (error) {
        console.error('An error occurred:', error);
    }
};




const LoginComponent = () => {

    const login = useGoogleLogin({

        onSuccess: (tokenResponse) => {

            fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`)
                .then(response => response.json())
                .then(data => {

                    //alert(`Hey ${data.given_name}`);
                    
                    sendDataToServer(data);

                    //console.log(data.email)

                    //document.location.href = '/dashboard';

                })
                .catch(error => console.error('Error fetching user info:', error));
        },
        onError: (error) => {
            console.error('Login Failed:', error);
        },
    });

    return (
        <div>
            <GoogleButton onClick={login} label="With Google"/>
        </div>
    );
};




export default LoginComponent;